import React from "react";
import ware from "../../../assets/images/wareImg.png";
import survey from "../../../assets/images/survey.jpg";
import blog3 from "../../../assets/images/blog3.png";
import blog4 from "../../../assets/images/blog4.png";
import blog6 from "../../../assets/images/blog6.jpg";
import blog7 from "../../../assets/images/blog7.jpg";
import masked from "../../../assets/images/masked.jpg";
import roi from "../../../assets/images/it-and-roi-hero.jpg";
import modifier from "../../../assets/images/modifier.jpg";
import { AiOutlineRight } from "react-icons/ai";

export default function Resources() {
  const resources = [
    {
      title: "Ecommerce",
      description: "Incoming project.",
      image: survey,
    },
    {
      title: "Donation",
      description: "Incoming project.",
      image: ware,
    },
    {
      title: "TPS travels",
      description: "Incoming project",
      image: blog4,
    },
    {
      title: "School App",
      description: "Incoming project",
      image: masked,
    },
    // {
    //   title: "Article",
    //   description: "TPS Remote Working Readiness Survey",
    //   image: blog3,
    // },
    // {
    //   title: "Article",
    //   description: "TPS Remote Working Readiness Survey",
    //   image: blog6,
    // },
    // {
    //   title: "Article",
    //   description: "TPS Remote Working Readiness Survey",
    //   image: roi,
    // },
    // {
    //   title: "Article",
    //   description: "TPS Remote Working Readiness Survey",
    //   image: modifier,
    // },
    // {
    //   title: "Article",
    //   description: "TPS Remote Working Readiness Survey",
    //   image: blog7,
    // },
  ];

  return (
    <section id="resourse" className="section">
      <div className="container">
        <div className="grid gap-4 md:gap-10 py-5 md:py-8 md:grid-cols-3">
          {/* items */}
          {resources.map((resource, index) => (
            <div
              key={index}
              className="mx-auto h-72 md:h-96 hover:scale-y-105 cursor-pointer relative rounded-md bg-black duration-200"
            >
              <img
                alt="LOADING"
                src={resource.image}
                className="object-cover opacity-20 min-h-full"
              />

              <div className="absolute left-10 top-10 md:left-20 md:top-20 text-left space-y-4">
                <p className="text-blue-400">{resource.title}</p>
                <h1 className="text-2xl text-white font-bold">
                  {resource.description}
                </h1>
                <div className="cursor-pointer rounded-full border-2 border-primary100 w-8 h-8 flex items-center justify-center">
                  <AiOutlineRight color="white" />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center mb-3">
          {/* <button className="px-8 py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm sm:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300">
            <p>View all Resources</p>
          </button> */}
        </div>
      </div>
    </section>
  );
}
