import React from "react";
import Navbar from "./Navbar";
import TPSSvg from "../../utils-component/tpsSvg";
import { Link } from "react-router-dom";
import lightbulb from "../../../assets/svg/light-bulb.svg";
import web from "../../../assets/svg/web.svg";
import user from "../../../assets/svg/user.svg";
import upload from "../../../assets/svg/upload.svg";
import expert from "../../../assets/svg/expert-nav.svg";
import hero from "../../../assets/images/typing.jpg";

//export default function Hero() {
const Hero = () => {
  return (
    <section id="hero" style={{ backgroundImage: `url(${hero})` }}>
      <Navbar />
      <div className="bg-black pt-14 md:pt-8 bg-opacity-70 min-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          <div className="text-left px-6 pb-10 md:p-12 md:pt-8 lg:p-14">
            <p className="text-sky-400 mb-10 font-semibold">
              Making I.T Work Smarter For Your Business
            </p>
            <h1 className="text-white text-5xl md:text-6xl font-semibold md:font-bold text-left mb-10">
              Distinguished IT services, operating for your businesses and your
              goals.
            </h1>
            <p className="text-white text-semibold text-left mb-10">
              With over 10 years of providing IT solutions, cyber security
              protection, mobile applications, communications infastructure and
              expert IT support services.
            </p>

            {/* BUTTONS */}
            <div className="flex flex-row items-center space-x-10">
              <div className="border-2 font-bold border-sky-600 px-10 py-3 rounded-full text-center text-white hover:bg-sky-600 duration-500">
                Our Services
              </div>

              <div className=" text-white font-semibold text-center border-b-2 border-b-sky-600 hover:border-b-0 duration-200">
                <Link to="/contact-us" className="text-center">
                  Get in touch
                </Link>
              </div>
            </div>
          </div>
          {/* svg/logo */}
          <div className="hidden md:block w-full md:2/3 lg:1/2 pt-20">
            <TPSSvg />
          </div>
        </div>

        {/* hero features */}
        <div className="grid grid-cols-2 text-xs py-6 px-6 md:px-14 items-center md:text-lg md:flex flex-row border-t-2 border-sky-800 md:space-y-0 md:space-x-20 md:justify-evenly">
          {/* item 1 */}
          <div className="flex-row items-center my-3 hidden md:flex md:my-0 space-x-2 md:space-x-5 text-white">
            <img alt="light-bulb" src={lightbulb} />
            <p>Est. 2012</p>
          </div>

          {/* item 2 */}
          <div className="flex flex-row items-center my-3 md:my-0 space-x-2 md:space-x-5 text-white ">
            <img alt="web" src={web} /> <p>Worldwide Support</p>
          </div>

          {/* item 3 */}
          <div className="flex flex-row items-center my-3 md:my-0 space-x-2 md:space-x-5 text-white">
            <img alt="user" src={user} /> <p>Team of Expert</p>
          </div>

          {/* item 4 */}
          <div className="flex flex-row items-center my-3 md:my-0 space-x-2 md:space-x-5 text-white ">
            <img alt="upload" src={upload} /> <p>Cloud Services</p>
          </div>
          {/* item 5 */}
          <div className="flex flex-row items-center my-3 md:my-0 space-x-2  md:space-x-5 text-white">
            <img alt="expert" src={expert} className="w-6" />{" "}
            <p>Expert Solutions</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
