import React from "react";
import cyber from "../../../../../assets/images/cyber-hero.jpg";
import adobe from "../../../../../assets/images/AdobeStock.jpg";
import phone from "../../../../../assets/images/Phon_call.jpg";

export default function RelatedBlog() {
  const reatedBlog = [
    {
      date: "29/01/2021",
      image: cyber,
      description: "A complete guide to Ransomeware and Malware",
    },
    {
      date: "25/03/2020",
      image: adobe,
      description: "A Guide on Cloud Computing",
    },
    {
      date: "09/12/2018",
      image: phone,
      description:
        "Anywhere, Anytime, Any Device: Staying Connectedwith Volp technology.  ",
    },
  ];

  return (
    // <section id="related-blog" className="px-6 pt-4 md:px-10 md:py-20">
    //   <h1 className="text-3xl md:text-5xl mb-10 text-primary-dark font-bold capitalize my-6">
    //     Related Blogs
    //   </h1>

    //   <div className="flex mx-auto flex-col md:grid md:grid-cols-3 md:gap-4 space-y-8 md:space-x-5 md:space-y-0">
    //     {/* content */}

    //     {reatedBlog.map((blog, index) => (
    //       <div key={index} className="space-y-4 cursor-pointer">
    //         <img
    //           alt=""
    //           src={blog.image}
    //           className="h-72 w-full hover:scale-105 hover:border-b-4 hover:border-blue-400 duration-500"
    //         />
    //         <div className="">
    //           <p className="text-blue-400">{blog.date}</p>
    //           <p className="text-xl my-5 font-bold text-black">
    //             {blog.description}
    //           </p>
    //           <p className="border-b-2 hover:border-b-0 duration-200 border-blue-400">
    //             Learn more
    //           </p>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </section>
    <section></section>
  );
}
