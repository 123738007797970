import React from "react";

export default function TextSection() {
  return (
    <div className="px-10 md:px-48 pt-20">
      <p className=" text-gray-600 text-left leading-10">
        {/* These terms and conditions (as amended under clause 32.2) (“Conditions”)
        govern the sale of goods and/or services and licensing of software by
        Computing information Systems Limited, registered in England and Wales
        with no. 02420578, with registered address at 6 Langdale Court, Witney,
        Oxfordshire, OX28 6FG (“TPS”) to the person/firm to who buys such
        goods/services (“Customer”). These Conditions apply to the exclusion of
        any other terms that Customer seeks to impose, or which are implied by
        trade, custom, practice or course of dealing. <br />{" "} */}
        <span className="mt-5">
          {/* ***Note particularly clause 25 (Limitation of Liability)*** */}
        </span>
      </p>

      {/* Interpretation */}
      <div>
        <h1 className="text-3xl font-bold mt-10 mb-7">
          {/* Interpretation */}
        </h1>
        <p className=" text-gray-600 text-left leading-10">
          {/* These terms and conditions (as amended under clause 32.2)
          (“Conditions”) govern the sale of goods and/or services and licensing
          of software by Computing information Systems Limited, registered in
          England and Wales with no. 02420578, with registered address at 6
          Langdale Court, Witney, Oxfordshire, OX28 6FG (“TPS”) to the
          person/firm to who buys such goods/services (“Customer”). These
          Conditions apply to the exclusion of any other terms that Customer
          seeks to impose, or which are implied by trade, custom, practice or
          course of dealing, or which are implied by trade, custom, practice or
          course of dealing. <br />{" "} */}
          <span className="mt-5">
            {/* ***Note particularly clause 25 (Limitation of Liability)*** */}
          </span>
        </p>

        <div className="mt-20">
          <p className="mt-10 mb-7">{/* 1. Interpretation */}</p>
          <p className=" text-gray-600 text-left mb-10 leading-10">
            {/* In these Conditions the following definitions apply: (i) person
            includes a natural person, corporate or unincorporated body; (ii) a
            reference to TPS or Customer includes its personal representatives,
            successors and permitted assigns; (iii) a reference to a statute or
            statutory provision is a reference to such statute or statutory
            provision as amended or re-enacted and includes any subordinate
            legislation; (iv) any phrase introduced by the terms including or
            include shall be illustrative and shall not limit the sense of the
            preceding words; (v) a reference to writing or written includes
            emails but excludes faxes; and (vi) the terms “Controller”,
            “Personal Data” and “Processor”, shall have the meanings attributed
            to them in the Data Protection Legislation, and “Processing” and
            “Process” shall be construed accordingly. <br />{" "} */}
          </p>
          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Acceptable Use Policy” (“AUP”): the policy applicable to the
            relevant Services, as detailed in the Quote, or any other acceptable
            use policy mandated by TPS from time to time on written notice to
            the Customer. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Additional Corrective Maintenance”: in accordance with clause
            15.5.2 and clause 16.1 of these Conditions: making any adjustments
            to the Maintained Equipment; and replacing any parts or components
            of the Maintained Equipment which are required to restore it to Good
            Working Order. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Additional Hardware Maintenance Services”: any Additional
            Corrective Maintenance and/or any Excluded Maintenance performed by
            TPS in accordance with these Conditions. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Business Day”: a day other than a Saturday, Sunday or public
            holiday in England when banks in London are open for business. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Call”: the transmission of voice or data provided by means of the
            Mobile Services or Telephony Services, as appropriate. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Caller Line Identity” (“CLI”): the identity of the calling party
            initiating a Call being (in most circumstances) the telephone number
            of the calling party. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Carrier Network”: the communications network or networks selected
            from time to time by TPS for the purposes of conveying Calls through
            the Mobile Services. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Charges”: the charges payable by the Customer to TPS, as set out in
            the relevant Order. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “TPS Personnel”: TPS’ employees, directors and agents, together with
            employees, directors and agents of any contractor undertaking
            activities on behalf of TPS in relation to the performance of its
            obligations under the applicable Contract. */}
          </p>

          <p className=" text-gray-600  text-left leading-10">
            {/* “Confidential Information”: information of commercial value, in
            whatever form or medium, disclosed by a party to the other party,
            including commercial or technical know-how, technology, information
            pertaining to business operations and strategies, information */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* pertaining to clients, pricing and marketing information relating to
            the business of either party, information which is marked as
            confidential, or information which ought reasonably to be considered
            confidential in light of the nature of the information and/or
            circumstances of its disclosure. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Contract”: each contract (as defined in clause 2.1) between the
            Customer and TPS relating to the supply of Goods, Services or the
            licensing of software. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Critical Fault”: a reproducible fault which hinders or prevents the
            Customer from using a material part of the functionality of the
            Supported Software. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Customer Data”: any data (including any Personal Data relating to
            the personnel, clients and/or suppliers of the Customer), documents,
            text, drawings, diagrams, images or sounds (together with any
            database made up of any of these), embodied in any medium, that are
            provided to TPS by or on behalf of the Customer, or which TPS is
            required to generate, Process, store or transmit pursuant to these
            Conditions or any Order. */}
          </p>

          <p className=" text-gray-600 mb-10 text-left leading-10">
            {/* “Customer Personnel”: employees, directors and agents of the
            Customer, together with employees, directors and agents of any
            contractor undertaking activities on behalf of the Customer who are
            not TPS Personnel. */}
          </p>
        </div>
      </div>

      {/* table */}
  
    </div>
  );
}
