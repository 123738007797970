import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import blog3 from "../../../assets/images/blog3.png";
import eazybillzlogo from "../../../assets/images/eazybillzlogo.png";
import vipre from "../../../assets/images/vipre.jpg";
import { Link } from "react-router-dom";
import { link } from "fs";
const sliderList = [
  {
    id: 1,
    img: eazybillzlogo,
    text1: "15/05/2020",
    text2: "Eazybillz, Best estate management system.",
    link: "https://lg3.eazybillz.com/",
  },
  // {
  //   id: 2,
  //   img: vipre,
  //   text1: "17/02/2021",
  //   text2: " Best Office Communication Systems",
  //   link: "https://lg3.eazybillz.com/",
  // },
  // {
  //   img: vipre,
  //   text1: "17/02/2021",
  //   text2: " Best Office Communication Systems",
  // },
  // {
  //   img: blog3,
  //   text1: "17/02/2021",
  //   text2: " Best Office Communication Systems",
  // },
  // {
  //   img: blog3,
  //   text1: "17/02/2021",
  //   text2: " Best Office Communication Systems",
  // },
  // {
  //   img: blog3,
  //   text1: "17/02/2021",
  //   text2: " Best Office Communication Systems",
  // },
];

const desktopSettings = {
  dots: true,
  infinite: false,
  speed: 4000,
  slidesToShow: 3,
};
const mobileSettings = {
  dots: true,
  infinite: true,
  speed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ResourceBlog = () => {
  return (
    <section
      id="resource-blog"
      className="bg-gradient-to-b from-slate-100 to-slate-0"
    >
      <div className="container">
        {/* top content */}

        <div className="flex flex-col space-y-4 md:flex-row justify-between my-10 md:items-center">
          {/* text */}
          <div className="space-y-6">
            {/* <h6 className="text-gray-700 "> Past Projects</h6> */}
            <h1 className="text-3xl md:text-5xl font-bold ">Past Projects</h1>
          </div>
        </div>

        {/* image flex container */}

        <div className="hidden md:block">
          <Slider autoplay autoplaySpeed={2000} {...desktopSettings}>
            {sliderList.map((slide, index) => {
              console.log("slide: ", slide);
              return (
                <div key={index} className="space-y-5 w-full  ">
                  <img
                    className=" w-96 hover:border-b-4 hover:border-blue-400 duration-200 hover:scale-105 h-60 rounded"
                    alt=""
                    src={slide.img}
                  />
                  <p className="text-slate-950 mb-4 mt-4 font-bold text-sm">
                    Released on: {slide.text1}
                  </p>
                  <h3 className="text-lg font-bold text-slate-950 mb-5">
                    {" "}
                    {slide.text2}
                  </h3>
                  <div className="group">
                    <p className="text-blue-600 mb-4 mt-4 font-bold text-sm">
                      <Link
                        className="flex items-center justify-center mt-10 md:mb-4"
                        to="https://lg3.eazybillz.com/"
                      >
                        Explore App
                      </Link>
                    </p>
                    <div className="mx-0 w-20 group-hover:border-b-2 group-hover:border-sky-500">
                      {" "}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>

        {/* mobile slider */}
        {/* <div className=" md:hidden">
          <Slider autoplay autoplaySpeed={2000} {...mobileSettings}>
            {slider.map((slide, index) => (
              <div key={index} className="space-y-5 w-full">
                <img
                  className=" w-80  hover:border-b-4 hover:border-blue-400 duration-200 hover:scale-105 h-60 rounded"
                  alt=""
                  src={slide.img}
                />
                <p className="text-blue-600 mb-4 mt-4 font-bold text-sm">
                  {slide.text1}
                </p>
                <h3 className="text-lg font-bold text-slate-950 mb-5">
                  {" "}
                  {slide.text2}
                </h3>
                <div className="group">
                  <p className="text-slate-950 text-lg">Learn more</p>
                  <div className="mx-0 w-20 group-hover:border-b-2 group-hover:border-sky-500">
                    {" "}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div> */}

        {/* <Link
          className="flex items-center justify-center mt-10 md:mb-4"
          to="/Products"
        >
          <button className="px-8 py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm sm:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300">
            View all
          </button>
        </Link> */}
      </div>
    </section>
  );
};

export default ResourceBlog;
