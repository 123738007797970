import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { FiPlus, FiMinus } from "react-icons/fi";
import { CgSearch } from "react-icons/cg";
import { VscChromeClose } from "react-icons/vsc";
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import TopMostInfo from "./TopMostInfo";
//import logo from "../../../assets/images/logo.png";
import logo from "../../../assets/images/logo3.png";
import lightbulb from "../../../assets/svg/light-bulb.svg";
import shield from "../../../assets/svg/shield.svg";
import text from "../../../assets/svg/text.svg";
import backup from "../../../assets/svg/backup.svg";
import expert from "../../../assets/svg/expert.svg";

const navlinks = [
  {
    link: "Services",
    submenu: true,
    sublink: [
      {
        image: lightbulb,
        title: "Cloud Solutions",
        text: "A wide range of cloud solutions for business",
        to: "/services/cloud-solutions",
      },
      {
        image: shield,
        title: "Cyber Security Services",
        text: "Trust your IT with Experts in deliverying IT support excellence.",
        to: "/services/cyber-security",
      },
      {
        image: text,
        title: "Communications",
        text: "Bespoke business communication systems.",
        to: "/services/communications",
      },
      {
        image: expert,
        title: "IT Support and Consultancy Services",
        text: "Expert IT services when you need it most.",
        to: "/services/it-support",
      },
      {
        image: backup,
        title: "Backup and Recovery",
        text: "Leading backup and recovery services for businesses.",
        to: "/services/backup-recovery",
      },
    ],
  },
  {
    link: "Challenges",
    submenu: true,
    sublink: [
      {
        title: "Old, Slow and Useless",
        text: "Dated IT Equipment can take a toll on your business.",
        to: "/challenges/old-slow-and-useless",
      },
      {
        title: "IT and ROI",
        text: "Efficient IT systems streamline business operations.",
        to: "/challenges/it-and-roi",
      },
      {
        title: "IT and your Business Ambitions",
        text: "Ensure your business objectives align with your IT strategy.",
        to: "",
      },
      {
        title: "Disaster Recovery! What's that?",
        text: "Don't be caught offguard by unforeseen circumstances.",
        to: "/challenges/disaster-recovery-whats-that",
      },
      {
        title: "Does IT Security Matter? The Importance of IT Security",
        text: "IT security is often an afterthought, find out why it matters.",
        to: "/challenges/it-security-does-it-matter",
      },
      {
        title: "Small Issues which create Big Problems",
        text: "Don't let small IT issues become business critical issues.",
        to: "/challenges/small-issues-which-create-big-problems",
      },
    ],
  },
  {
    link: "About",
    submenu: false,
    to: "/about",
  },
  // {
  //   link: "Success stories",
  //   submenu: false,
  //   to: "/success-stories",
  // },
  {
    link: "Products",
    submenu: false,
    to: "/Products",
  },
  {
    link: "Blog",
    submenu: false,
    to: "/blog",
  },
  {
    link: "FAQs",
    submenu: false,
    to: "/faqs",
  },
];

type Props = {};

const Navbar = (props: Props) => {
  const [navbar, setNavbar] = useState(false);
  const openNavbar = () => setNavbar(true);
  const closeNavbar = () => setNavbar(false);
  const [stickyBar, setStickyBar] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update the state as the user types
  };

  // FEATURE: STICKY NAVBAR AND CHANGE BG COLOUR ON SCROLL
  const changeBackground = () => {
    if (window.scrollY >= 5) {
      setStickyBar(true);
    } else {
      setStickyBar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <header
      className={`" z-50" ${
        stickyBar ? "fixed top-0 left-0 right-0 bg-black bg-opacity-70" : ""
      }`}
      style={{ zIndex: "9999" }}
    >
      <TopMostInfo />
      <div className="relative bg-black bg-opacity-70">
        <nav
          className={`flex items-center justify-between py-2 px-7 md:p-7 bg-transparent text-white hover:bg-black border-b-2 h-20 md:h-28 ${
            stickyBar ? "border-black " : "border-b-primary"
          } `}
        >
          <Link to="/">
            <Logo />
          </Link>

          <ul className="hidden lg:flex items-center gap-6">
            {navlinks.map((items, index) => {
              const { link, submenu, sublink, to } = items;
              return (
                <li className="group" key={index}>
                  {submenu ? (
                    <div>
                      <div className="md:cursor-pointer group">
                        <p className="flex justify-between items-center group font-medium text-white hover:text-primary100 focus:text-primary100 py-12 md:pr-0 pr-5 text-base">
                          {link}
                          <span className="block group-hover:hidden text-primary100 ml-0.5 mt-0.5 text-base">
                            <FiPlus />
                          </span>
                          <span className="hidden group-hover:block text-primary100 ml-0.5 mt-[3px] text-base">
                            <FiMinus />
                          </span>
                        </p>
                        {sublink && <Dropdown sublink={sublink} />}
                      </div>
                    </div>
                  ) : (
                    <Link to={to} className="text-lg hover:text-primary100">
                      {link}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>

          <div className="flex items-center gap-6">
            <div className="hidden sm:flex items-center ml-auto lg:ml-0">
              <div className="relative mr-4">
                <form
                  role="search"
                  method="get"
                  id="searchform"
                  className={`${
                    openForm
                      ? "is-active nav-form"
                      : "overflow-hidden nav-form scale-x-0"
                  }`}
                >
                  {/* <input
                    placeholder="Type search here"
                    type="text"
                    value=""
                    name="s"
                    id="s"
                    required
                    className="bg-white border-[3px] border-primary100 outline-primary100 rounded-full pl-4 pr-8 h-10"
                  /> */}
                  <input
                    placeholder="Type search here"
                    type="text"
                    value={inputValue} // Bind the input's value to the state
                    name="s"
                    id="s"
                    required
                    onChange={handleInputChange} // Handle changes when the user types
                    className="bg-white border-[3px] border-primary100 outline-primary100 rounded-full pl-4 pr-8 h-10"
                  />
                  <input className="hidden" type="submit" value="Search" />
                </form>
                <div
                  className={`${
                    openForm
                      ? "search-icon bg-primary100"
                      : "search-icon hidden sm:block hover:bg-primary100"
                  }`}
                  onClick={() => setOpenForm(true)}
                >
                  <CgSearch size={18} />
                </div>
              </div>
              <Link
                to="/contact-us"
                className="border-2 border-primary100 px-5 py-2 rounded-3xl text-sm text-white lg:text-sm hover:bg-primary100"
              >
                Get in touch
              </Link>
            </div>

            <div className="cursor-pointer lg:hidden" onClick={openNavbar}>
              <FaBars size={23} />
            </div>
          </div>

          {/* Mobile Menu */}
          {navbar && <MobileNav close={closeNavbar} />}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;

const MobileNav = ({ close }) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <div className="bg-[#1e1e1e] w-full h-screen fixed top-0 left-0 right-0 bottom-0 overflow-y-scroll lg:hidden transition-all duration-700 z-[80] pb-10">
      <div className="flex items-center justify-between pt-5 px-5">
        <Link to="/" className="w-36">
          <Logo />
        </Link>

        <span className="text-white hover:cursor-pointer" onClick={close}>
          <VscChromeClose size={30} />
        </span>
      </div>

      <ul className="my-8">
        {navlinks.map((items, index) => {
          const { link, submenu, sublink, to } = items;
          return (
            <li
              key={index}
              className="w-full border-b border-b-neutral-200 py-3 px-6 mb-3"
            >
              {submenu ? (
                <>
                  <div
                    className="flex items-center justify-between"
                    onClick={() => setDropdown(!dropdown)}
                  >
                    <p className="text-lg font-semibold text-white">{link}</p>
                    <span className="cursor-pointer text-primary">
                      {dropdown ? (
                        <PiCaretUpBold size={20} />
                      ) : (
                        <PiCaretDownBold size={20} />
                      )}
                    </span>
                  </div>
                  {dropdown && (
                    <MobileDropdown sublink={sublink} dropdown={dropdown} />
                  )}
                </>
              ) : (
                <Link to={to} className="text-lg font-semibold text-white">
                  {link}
                </Link>
              )}
            </li>
          );
        })}
      </ul>

      <Link
        to="/contact-us"
        className="bg-transparent border-2 mx-4 my-10 px-8 py-3 border-primary100 rounded-full transition hover:cursor-pointer hover:bg-sky-500"
      >
        Get in touch
      </Link>
    </div>
  );
};

const Dropdown = ({ sublink }) => {
  return (
    <div className="hover:md:block">
      <div className="absolute top-28 left-0 hidden group-hover:md:block w-full bg-white z-[100]">
        <ul className="bg-white pt-20 pb-24 flex items-center flex-wrap mx-auto gap-x-10 gap-y-12 lg:w-[90%]">
          {sublink.map((item, index) => {
            const { image, title, text, to } = item;
            return (
              <li key={index} className="basis-[22rem]">
                <Link to={to} className="flex flex-col items-start space-y-3">
                  {image ? (
                    <img src={image} className="w-10 h-auto" alt={title} />
                  ) : (
                    <span className="text-primary100 text-2xl">
                      <IoIosArrowForward />
                    </span>
                  )}
                  <Link to={to} className="text-xl text-slate-900 font-bold">
                    {title}
                  </Link>
                  <p className="text-base text-slate-700 font-normal">{text}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const Logo = () => {
  return <img src={logo} alt="logo" className="w-36" />;
};

const MobileDropdown = ({ sublink, dropdown }) => {
  return (
    <ul
      className={`${dropdown} ? 'flex flex-col space-y-6 my-6 mx-6' : 'hidden'`}
    >
      {sublink.map((item, index) => {
        const { image, title, to } = item;
        return (
          <li
            key={index}
            className="flex items-center gap-4 text-base font-normal"
          >
            {image && <img src={image} alt={title} className="w-6" />}
            <Link to={to}>{title}</Link>
          </li>
        );
      })}
    </ul>
  );
};
