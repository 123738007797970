import React from 'react'
import { Link } from 'react-router-dom';
import blog1 from '../../../../../assets/images/Blog1.png';
import blog2 from "../../../../../assets/images/blog2.png"
import blog3 from "../../../../../assets/images/blog3.png"

type Props = {}

const MobileBlog = (props: Props) => {
  return (
    // <section className="py-5 md:py-14" id="linear-bg">
    //   <div className="max-w-screen-xl mx-auto px-7">
    //     <h3 className="text-primary-dark text-3xl md:text-4xl font-bold mb-8">
    //       Related Blogs
    //     </h3>

    //     <div className="item-container inline-block cursor-pointer mb-8">
    //       {/* image 1 */}
    //       <div className="group relative rounded mb-30 overflow-hidden md:w-1/2">
    //         <img
    //           src={blog1}
    //           alt=""
    //           className="w-full h-60 object-cover object-center"
    //         />
    //         <p className="text-blue-600 mb-4 mt-4 font-bold text-sm">
    //           17/02/2021
    //         </p>
    //         <h3 className="text-lg font-bold text-slate-950 mb-5">
    //           Vipre: Edge Defence Security Solution
    //         </h3>
    //         <div className="group">
    //           <h4 className="text-slate-950 text-lg">Learn more</h4>
    //           <div className="mx-0 w-20 group-hover:border-b-2 group-hover:border-sky-500">
    //             {" "}
    //           </div>
    //         </div>
    //       </div>

    //       {/* image 2 */}
    //       <div className="group relative rounded mb-30 overflow-hidden md:w-1/2">
    //         <img
    //           src={blog2}
    //           alt=""
    //           className="w-full h-60 object-cover object-center"
    //         />
    //         <p className="text-blue-600 mb-4 mt-4 font-bold text-sm">
    //           17/02/2021
    //         </p>
    //         <h3 className="text-lg font-bold text-slate-950 mb-5">
    //           What does an IT support company do?
    //         </h3>
    //         <div className="group">
    //           <h4 className="text-slate-950 text-lg">Learn more</h4>
    //           <div className="mx-0 w-20 group-hover:border-b-2 group-hover:border-sky-500">
    //             {" "}
    //           </div>
    //         </div>
    //       </div>

    //       {/* image 3 */}
    //       <div className="group relative rounded mb-30 overflow-hidden md:w-1/2">
    //         <img
    //           src={blog3}
    //           alt=""
    //           className="w-full h-60 object-cover object-center"
    //         />
    //         <p className="text-blue-600 mb-4 mt-4 font-bold text-sm">
    //           17/02/2021
    //         </p>
    //         <h3 className="text-lg font-bold text-slate-950 mb-5">
    //           What is an IT audit? The definitive guide
    //         </h3>
    //         <div className="group">
    //           <h4 className="text-slate-950 text-lg">Learn more</h4>
    //           <div className="mx-0 w-20 group-hover:border-b-2 group-hover:border-sky-500">
    //             {" "}
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     <div className="flex justify-start md:justify-center">
    //       <Link
    //         to="/blog"
            
    //       >
    //         <button className="px-6 py-2 md:px-8 md:py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm md:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300">
    //         More Blog Posts

    //         </button>
    //       </Link>
    //     </div>
    //   </div>
    // </section>
    <section></section>
  );
}

export default MobileBlog