import React from "react";
import { BiSolidGrid } from "react-icons/bi";
import { Link } from "react-router-dom";

const SupportBtn = () => {
  return (
    <div id="support-bg" className="py-7 lg:py-14">
      <div className="max-w-screen-xl px-8 mx-auto">
        <nav className="flex flex-wrap justify-between">
          <Link
            to="/Products"
            className="bg-white flex rounded-full p-4 px-6 mx-auto items-center text-primary-dark transition-all duration-300 hover:shadow-md"
          >
            <span className="text-lg font-medium">View Knowledge Base</span>
            <BiSolidGrid className="text-primary100 text-lg ml-1 mt-1" />
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default SupportBtn;
